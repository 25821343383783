import { defineStore } from 'pinia';
import { parse, stringify } from 'zipson';

import env from '@/env';
import { backendApi, AxiosError } from '@/http';
import {
  OrganizationType,
  GoalType,
  ValueType,
  TeamType,
  MemberType,
  PartnerType,
  ReportType,
  IdentityType,
  GovernanceType,
  AlbumType,
  PostType,
  ProjectType,
  SupportMessageType,
  ContactChannelType,
  SliderType,
} from '@/composables/types';
import { $t } from '@/i18n';

type GlobalState = {
  _organization: OrganizationType;
  _goals: GoalType[];
  _values: ValueType[];
  _teams: TeamType[];
  _members: MemberType[];
  _partners: PartnerType[];
  _reports: ReportType[];
  _identities: IdentityType[];
  _governances: GovernanceType[];
  _albums: AlbumType[];
  _news: PostType[];
  _articals: PostType[];
  _events: PostType[];
  _products: PostType[];
  _projects: ProjectType[];
  _supportMessages: SupportMessageType[];
  _contactChannels: ContactChannelType[];
  _sliders: SliderType[];
};

export default function useGlobalStore() {
  return defineStore('global', {
    state: (): GlobalState => ({
      _organization: {} as OrganizationType,
      _goals: [],
      _values: [],
      _teams: [],
      _members: [],
      _partners: [],
      _reports: [],
      _identities: [],
      _governances: [],
      _albums: [],
      _news: [],
      _articals: [],
      _events: [],
      _products: [],
      _projects: [],
      _supportMessages: [],
      _contactChannels: [],
      _sliders: [],
    }),
    getters: {
      organization(state): OrganizationType {
        return state._organization;
      },
      goals(state): GoalType[] {
        return state._goals;
      },
      values(state): ValueType[] {
        return state._values;
      },
      teams(state): TeamType[] {
        return state._teams;
      },
      members(state): MemberType[] {
        return state._members;
      },
      partners(state): PartnerType[] {
        return state._partners;
      },
      reports(state): ReportType[] {
        return state._reports;
      },
      identities(state): IdentityType[] {
        return state._identities;
      },
      governances(state): GovernanceType[] {
        return state._governances;
      },
      albums(state): AlbumType[] {
        return state._albums;
      },
      articals(state): PostType[] {
        return state._articals;
      },
      news(state): PostType[] {
        return state._news;
      },
      products(state): PostType[] {
        return state._products;
      },
      events(state): PostType[] {
        return state._events;
      },
      projects(state): ProjectType[] {
        return state._projects;
      },
      supportMessages(state): SupportMessageType[] {
        return state._supportMessages;
      },
      contactChannels(state): ContactChannelType[] {
        return state._contactChannels;
      },
      sliders(state): SliderType[] {
        return state._sliders;
      },
    },
    actions: {
      async fetchOrganization() {
        try {
          const result = await backendApi.get('organizations/1');
          this._organization = result.data.data;
        } catch (error: unknown) {
          this.handleError(error);
          this._organization = {} as OrganizationType;
        }
      },
      async fetchGoals(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'goals',
            getParams(paginate, [
              { key: 'status', operator: '=', value: 'shown' } as FilterType,
            ])
          );
          this._goals = result.data.data;
        } catch (error: unknown) {
          this.handleError(error);
          this._goals = [];
        }
      },
      async fetchValues(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'values',
            getParams(paginate, [
              { key: 'status', operator: '=', value: 'shown' } as FilterType,
            ])
          );
          this._values = result.data.data;
        } catch (error: unknown) {
          this.handleError(error);
          this._values = [];
        }
      },
      async fetchTeams(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'teams',
            getParams(paginate, [
              { key: 'status', operator: '=', value: 'shown' } as FilterType,
            ])
          );
          this._teams = result.data.data;
        } catch (error: unknown) {
          this.handleError(error);
          this._teams = [];
        }
      },
      async fetchMembers(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'members',
            getParams(paginate, undefined)
          );
          this._members = result.data.data;
        } catch (error: unknown) {
          this.handleError(error);
          this._members = [];
        }
      },
      async fetchPartners(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'partners',
            getParams(paginate, [
              { key: 'status', operator: '=', value: 'shown' } as FilterType,
            ])
          );
          this._partners = result.data.data;
        } catch (error: unknown) {
          this.handleError(error);
          this._partners = [];
        }
      },
      async fetchReports(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'reports',
            getParams(paginate, [
              { key: 'status', operator: '=', value: 'shown' } as FilterType,
            ])
          );
          this._reports = result.data.data;
        } catch (error: unknown) {
          this.handleError(error);
          this._reports = [];
        }
      },
      async fetchIdentities(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'identities',
            getParams(paginate, [
              { key: 'status', operator: '=', value: 'shown' } as FilterType,
            ])
          );
          this._identities = result.data.data;
        } catch (error: unknown) {
          this.handleError(error);
          this._identities = [];
        }
      },
      async fetchGovernances(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'governances',
            getParams(paginate, [
              { key: 'status', operator: '=', value: 'shown' } as FilterType,
            ])
          );
          this._governances = result.data.data;
        } catch (error: unknown) {
          this.handleError(error);
          this._governances = [];
        }
      },
      async fetchAlbums(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'albums',
            getParams(paginate, [
              { key: 'status', operator: '=', value: 'shown' } as FilterType,
            ])
          );
          this._albums = result.data.data;
        } catch (error: unknown) {
          this.handleError(error);
          this._albums = [];
        }
      },
      fetchArticals(paginate: number | undefined) {
        this._fetchPosts(paginate, 'artical')
          .then((posts: PostType[]) => {
            this._articals = posts;
          })
          .catch((error: unknown) => {
            this.handleError(error);
            this._articals = [];
          });
      },
      fetchNews(paginate: number | undefined) {
        this._fetchPosts(paginate, 'news')
          .then((posts: PostType[]) => {
            this._news = posts;
          })
          .catch((error: unknown) => {
            this.handleError(error);
            this._news = [];
          });
      },
      fetchEvents(paginate: number | undefined) {
        this._fetchPosts(paginate, 'event')
          .then((posts: PostType[]) => {
            this._events = posts;
          })
          .catch((error: unknown) => {
            this.handleError(error);
            this._events = [];
          });
      },
      fetchProducts(paginate: number | undefined) {
        this._fetchPosts(paginate, 'product')
          .then((posts: PostType[]) => {
            this._products = posts;
          })
          .catch((error: unknown) => {
            this.handleError(error);
            this._products = [];
          });
      },
      async _fetchPosts(
        paginate: number | undefined,
        type: 'news' | 'event' | 'artical' | 'product' | undefined
      ): Promise<PostType[]> {
        const filter: FilterType[] = [
          { key: 'status', operator: '=', value: 'shown' } as FilterType,
        ];

        if (type) {
          filter.push({
            key: 'type',
            operator: '=',
            value: type,
          } as FilterType);
        }
        const result = await backendApi.get(
          'posts',
          getParams(paginate, filter)
        );
        return result.data.data;
      },
      async fetchProjects(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'projects',
            getParams(paginate, [
              { key: 'status', operator: '=', value: 'shown' } as FilterType,
            ])
          );
          this._projects = result.data.data;
        } catch (error: unknown) {
          this.handleError(error);
          this._projects = [];
        }
      },
      async fetchSupportMessages(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'support-messages',
            getParams(paginate, undefined)
          );
          this._supportMessages = result.data.data;
        } catch (error: unknown) {
          this.handleError(error);
          this._supportMessages = [];
        }
      },
      async fetchContactChannels(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'contact-channels',
            getParams(paginate, [
              { key: 'status', operator: '=', value: 'shown' } as FilterType,
            ])
          );
          this._contactChannels = result.data.data;
        } catch (error: unknown) {
          this.handleError(error);
          this._contactChannels = [];
        }
      },
      async fetchSliders(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'sliders',
            getParams(paginate, [
              { key: 'status', operator: '=', value: 'shown' } as FilterType,
            ])
          );
          this._sliders = result.data.data;
        } catch (error: unknown) {
          this.handleError(error);
          this._contactChannels = [];
        }
      },
      contactChannelByType(type: string): ContactChannelType | null {
        if (this._contactChannels.length <= 0) {
          return null;
        }

        const firstContactChannel = this._contactChannels.find(
          (contactChannel) => contactChannel.type === type
        );

        if (firstContactChannel) {
          return firstContactChannel as ContactChannelType;
        } else {
          return null;
        }
      },
      async createVisit(path: string, pageTitle: string) {
        try {
          await backendApi.post('visitors', {
            page_title: pageTitle,
            page_url: path,
          });
        } catch (error: unknown) {
          this.handleError(error);
        }
      },
      handleError(error: unknown) {
        let message = $t('FAILED_TO_LOAD_DATA');
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message = error.response.data.message;
        }
        console.log(message);
      },
    },
    persist: {
      debug: env.mode === 'local',
      key: env.app.webstorageNamespace + '.auth.store',
      storage: window.localStorage,
      serializer: {
        deserialize: parse,
        serialize: stringify,
      },
    },
  })();
}

type FilterType = {
  key: string | null;
  operator: '=' | '!=' | '>' | '>=' | '<' | '<=' | 'like';
  value: string | number | boolean | null;
};

function getParams(
  paginate: number | undefined,
  filters: FilterType[] | undefined
) {
  return {
    params: {
      paginate: paginate != undefined ? paginate : null,
      page: paginate != undefined ? 1 : null,
      filter: filters != undefined ? filters : null,
    },
  };
}

export default {
  ACCEPTED: 'accepted',
  ACCOUNTS: 'Accounts',
  ACTIVE: 'active',
  ADD_IMAGES: 'add images',
  ADD_MEMBER: 'add member',
  ADD_RESPONSIBILITY: 'add responsibility',
  ADD_VIDEO: 'add video',
  ADDING_IMAGES: 'Adding Images',
  ADDING_VIDEO: 'Adding Video',
  ADDRESS_AR: 'Address [عربي]',
  ADDRESS_EN: 'Address [English]',
  AL_OTAISHAN_CHARITY_VERSION: 'Al-Otaishan Charity Version 1.0',
  ALBUM_IMAGES_AND_VIDEOS: 'Album images & videos',
  ALBUM_IMAGES: 'Album Images',
  All: 'All',
  ALOTAISHAN_CHARITY: 'ALOTAISHAN CHARITY',
  AN_ERROR_OCCURED_PLEASE_TRY_AGAIN_LATER:
    'An error occured, please try again later!',
  AR: 'Ar',
  ARABIC_TEXT: 'Arabic text',
  ARABIC: 'Arabic',
  ARTICAL: 'Artical',
  ARTICALS: 'Articals',
  BLOCKED: 'blocked',
  CANCEL: 'cancel',
  CANCELED: 'canceled',
  CAPTION_AR: 'Caption [عربي]',
  CAPTION_EN: 'Caption [English]',
  CLEAR_SEARCH: 'Clear Search',
  CLEAR: 'clear',
  CLOSE: 'Close',
  CONTACT_CHANNELS_LIST: 'Contact Channels List',
  CONTACT_CHANNELS: 'Contact Channels',
  CONTENT: 'Content',
  COVER_IMAGE: 'Cover Image',
  DASHBOARD: 'Dashboard',
  DELETE_ALBUM: 'Delete Album',
  DELETE_CONTACT_CHANNEL: 'Delete Contact Channel',
  DELETE_GOAL: 'Delete Goal',
  DELETE_GOVERNANCE: 'Delete Governance',
  DELETE_IDENTITY: 'Delete Identity',
  DELETE_MEMBER: 'Delete Member',
  DELETE_MESSAGE: 'Delete Message',
  DELETE_ORGANIZATION: 'Delete Organization',
  DELETE_PARTNER: 'Delete Partner',
  DELETE_POST: 'Delete Post',
  DELETE_PROJECT: 'Delete Project',
  DELETE_REPORT: 'Delete Report',
  DELETE_SLIDER: 'Delete Banner',
  DELETE_TEAM: 'Delete Team',
  DELETE_USER: 'Delete User',
  DELETE_VALUE: 'Delete Value',
  DELETE: 'Delete',
  DELETED: 'deleted',
  DESCRIPTION_AR: 'Description [عربي]',
  DESCRIPTION_EN: 'Description [English]',
  DESCRIPTION: 'Description',
  EDIT: 'edit',
  EMAIL: 'Email',
  EN: 'En',
  ENGLISH_TEXT: 'English text',
  ENGLISH: 'English',
  EVENT: 'Event',
  FACEBOOK: 'Facebook',
  FAVORITE: 'favorite',
  FAX: 'Fax',
  FILE_LINK: 'File Link',
  FILE_WAS_REMOVED_SUCCESSFULLY: 'File was removed successfully',
  FILE_WAS_UPLOADED_SUCCESSFULLY: 'File was uploaded successfully',
  FILE: 'File',
  FILES: 'Files',
  FILTER: 'filter',
  GOALS_LIST: 'Goals List',
  GOOGLE_MAP_SOURCE: 'Google Map Source',
  GOOGLE_MAP: 'Google Map',
  GOVERNANCES_LIST: 'Governances List',
  HIDDEN: 'hidden',
  ICON: 'Icon',
  IDENTITY_LIST: 'Identity List',
  INACTIVE: 'inactive',
  INSTAGRAM: 'Instagram',
  KEYWORDS: 'Keywords',
  LANGUAGE: 'Language',
  LINK: 'Link',
  LINKEDIN: 'Linkedin',
  LOGIN: 'Login',
  LOGO_WAS_REMOVED_SUCCESSFULLY: 'Logo was removed successfully',
  LOGO_WAS_UPLOADED_SUCCESSFULLY: 'Logo was uploaded successfully',
  LOGO: 'Logo',
  MEMBER: 'Member',
  MEMBERS_LIST: 'Members List',
  MEMBERS_TITLE_AR: 'Members Title [عربي]',
  MEMBERS_TITLE_EN: 'Members Title [English]',
  MEMBERS: 'Members',
  MENU: 'Menu',
  MESSAGE: 'Message',
  MISSION_AR: 'Mission [AR]',
  MISSION_EN: 'Mission [EN]',
  NAME_AR: 'Name [عربي]',
  NAME_EN: 'Name [English]',
  NAME: 'name',
  NEW_RECORD: 'new record',
  OPEN_THE_VIDEO: 'Open the video',
  ORGANIZATIONS_LIST: 'Organizations List',
  OTHER: 'Other',
  OUR_ORGANIZATION: 'Our Organization',
  PAGE_TITLE: 'Page Title',
  PAID: 'paid',
  PARTNER_LOGO: 'Partner Logo',
  PARTNER: 'Partner',
  PASSWORD_CONFIRMATION: 'Password Confirmation',
  PASSWORD: 'Password',
  PENDING: 'pending',
  PERSONAL_IMAGE: 'Personal Image',
  PHONE: 'phone',
  PORJECT_IMAGES: 'Porject Images',
  POST_IMAGES: 'Post Images',
  POSTS_AND_PROJECT: 'Posts & Project',
  POSTS_LIST: 'Posts List',
  POSTS: 'Posts',
  PRINT: 'print',
  PRODUCT: 'Product',
  PRODUCTS: 'Products',
  PROJECTS: 'Projects',
  REFRESH: 'refresh',
  REJECTED: 'rejected',
  REMOVE: 'remove',
  REPORT_FILE: 'Report File',
  REQUESTED: 'requested',
  RESPONSE: 'Response',
  RESPONSED: 'responsed',
  RESPONSIBILITIES: 'Responsibilities',
  ROLE_AR: 'Role [عربي]',
  ROLE_EN: 'Role [English]',
  SEARCH: 'Search',
  SELECTED: 'selected',
  SHOW_REPORT_FILE: 'show report file',
  SHOW: 'show',
  SHOWN: 'shown',
  SIGN_OUT: 'Sign Out',
  SLIDER_LIST: 'Banner List',
  SLIDERS: 'Banners',
  SNAPCHAT: 'Snapchat',
  STATUS: 'status',
  SUBMIT: 'submit',
  SUBTITLE_AR: 'Subtitle [عربي]',
  SUBTITLE_EN: 'Subtitle [English]',
  SUPPORT_MESSAGES_LIST: 'Support Messages List',
  SUPPORT_MESSAGES: 'Support Messages',
  SYSTEM_USERS: 'System Users',
  TEAMS: 'Teams',
  TELEGRAM: 'Telegram',
  TELEPHONE: 'Telephone',
  TIKTOK: 'Tiktok',
  TITLE_AR: 'Title [عربي]',
  TITLE_EN: 'Title [English]',
  TITLE: 'Title',
  TWITTER: 'Twitter',
  TYPE: 'Type',
  UNKNOWN: 'Unknown',
  UNPAID: 'Unpaid',
  UPLOAD: 'Upload',
  USERS_LIST: 'Users List',
  USERS: 'Users',
  VALUE_AR: 'Value [عربي]',
  VALUE_EN: 'Value [English]',
  VALUE: 'Value',
  VALUES_LIST: 'Values List',
  VIDEO_LINK: 'Video Link',
  VISION_AR: 'Vision [عربي]',
  VISION_EN: 'Vision [English]',
  VISITOR_LIST: 'Visitor List',
  VISITORS: 'Visitors',
  WHATSAPP: 'Whatsapp',
  X: 'X',
  YOUTUBE: 'Youtube',
};

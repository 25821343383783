export default {
  AFGHANISTAN: 'أفغانستان',
  ALBANIA: 'ألبانيا',
  ALGERIA: 'الجزائر',
  AMERICAN_SAMOA: 'ساموا الأمريكية',
  ANDORRA: 'أندورا',
  ANGOLA: 'أنغولا',
  ANGUILLA: 'أنغيلا',
  ANTARCTICA: 'القارة القطبية الجنوبية',
  ANTIGUA_AND_BARBUDA: 'أنتيغوا وبربودا',
  ARGENTINA: 'الأرجنتين',
  ARMENIA: 'أرمينيا',
  ARUBA: 'أروبا',
  AUSTRALIA: 'أستراليا',
  AUSTRIA: 'النمسا',
  AZERBAIJAN: 'أذربيجان',
  BAHAMAS: 'جزر البهاما',
  BAHRAIN: 'البحرين',
  BANGLADESH: 'بنغلاديش',
  BARBADOS: 'بربادوس',
  BELARUS: 'بيلاروسيا',
  BELGIUM: 'بلجيكا',
  BELIZE: 'بليز',
  BENIN: 'بنين',
  BERMUDA: 'برمودا',
  BHUTAN: 'بوتان',
  BOLIVIA: 'بوليفيا',
  BOSNIA_AND_HERZEGOVINA: 'البوسنة والهرسك',
  BOTSWANA: 'بوتسوانا',
  BRAZIL: 'البرازيل',
  BRITISH_INDIAN_OCEAN_TERRITORY: 'إقليم المحيط البريطاني الهندي',
  BRITISH_VIRGIN_ISLANDS: 'جزر فيرجن البريطانية',
  BRUNEI: 'بروناي',
  BULGARIA: 'بلغاريا',
  BURKINA_FASO: 'بوركينا فاسو',
  BURUNDI: 'بوروندي',
  CAMBODIA: 'كمبوديا',
  CAMEROON: 'الكاميرون',
  CANADA: 'كندا',
  CAPE_VERDE: 'الرأس الأخضر',
  CAYMAN_ISLANDS: 'جزر كايمان',
  CENTRAL_AFRICAN_REPUBLIC: 'جمهورية افريقيا الوسطى',
  CHAD: 'تشاد',
  CHILE: 'شيلي',
  CHINA: 'الصين',
  CHRISTMAS_ISLAND: 'جزيرة عيد الميلاد',
  COCOS_ISLANDS: 'جزر كوكوس',
  COLOMBIA: 'كولومبيا',
  COMOROS: 'جزر القمر',
  COOK_ISLANDS: 'جزر كوك',
  COSTA_RICA: 'كوستا ريكا',
  CROATIA: 'كرواتيا',
  CUBA: 'كوبا',
  CURACAO: 'كوراكاو',
  CYPRUS: 'قبرص',
  CZECH_REPUBLIC: 'الجمهورية التشيكية',
  DEMOCRATIC_REPUBLIC_OF_THE_CONGO: 'جمهورية الكونغو الديموقراطية',
  DENMARK: 'الدنمارك',
  DJIBOUTI: 'جيبوتي',
  DOMINICA: 'دومينيكا',
  DOMINICAN_REPUBLIC: 'جمهورية الدومينيكان',
  EAST_TIMOR: 'تيمور الشرقية',
  ECUADOR: 'الاكوادور',
  EGYPT: 'مصر',
  EL_SALVADOR: 'السلفادور',
  EQUATORIAL_GUINEA: 'غينيا الإستوائية',
  ERITREA: 'إريتريا',
  ESTONIA: 'إستونيا',
  ETHIOPIA: 'أثيوبيا',
  FALKLAND_ISLANDS: 'جزر فوكلاند',
  FAROE_ISLANDS: 'جزر فاروس',
  FIJI: 'فيجي',
  FINLAND: 'فنلندا',
  FRANCE: 'فرنسا',
  FRENCH_POLYNESIA: 'بولينيزيا الفرنسية',
  GABON: 'الجابون',
  GAMBIA: 'غامبيا',
  GEORGIA: 'جورجيا',
  GERMANY: 'ألمانيا',
  GHANA: 'غانا',
  GIBRALTAR: 'جبل طارق',
  GREECE: 'اليونان',
  GREENLAND: 'الأرض الخضراء',
  GRENADA: 'غرينادا',
  GUAM: 'غوام',
  GUATEMALA: 'غواتيمالا',
  GUERNSEY: 'غيرنسي',
  GUINEA: 'غينيا',
  GUINEA_Bissau: 'غينيا بيساو',
  GUYANA: 'غيانا',
  HAITI: 'هايتي',
  HONDURAS: 'هندوراس',
  HONG_KONG: 'هونج كونج',
  HUNGARY: 'هنغاريا',
  ICELAND: 'أيسلندا',
  INDIA: 'الهند',
  INDONESIA: 'إندونيسيا',
  IRAN: 'إيران',
  IRAQ: 'العراق',
  IRELAND: 'أيرلندا',
  ISLE_OF_MAN: 'جزيرة آيل أوف مان',
  ITALY: 'إيطاليا',
  IVORY_COAST: 'ساحل العاج',
  JAMAICA: 'جامايكا',
  JAPAN: 'اليابان',
  JERSEY: 'جيرسي',
  JORDAN: 'الأردن',
  KAZAKHSTAN: 'كازاخستان',
  KENYA: 'كينيا',
  KIRIBATI: 'كيريباتي',
  KOSOVO: 'كوسوفو',
  KUWAIT: 'الكويت',
  KYRGYZSTAN: 'قيرغيزستان',
  LAOS: 'لاوس',
  LATVIA: 'لاتفيا',
  LEBANON: 'لبنان',
  LESOTHO: 'ليسوتو',
  LIBERIA: 'ليبيريا',
  LIBYA: 'ليبيا',
  LIECHTENSTEIN: 'ليختنشتاين',
  LITHUANIA: 'ليتوانيا',
  LUXEMBOURG: 'لوكسمبورغ',
  MACAU: 'ماكاو',
  MACEDONIA: 'مقدونيا',
  MADAGASCAR: 'مدغشقر',
  MALAWI: 'ملاوي',
  MALAYSIA: 'ماليزيا',
  MALDIVES: 'جزر المالديف',
  MALI: 'مالي',
  MALTA: 'مالطا',
  MARSHALL_ISLANDS: 'جزر مارشال',
  MAURITANIA: 'موريتانيا',
  MAURITIUS: 'موريشيوس',
  MAYOTTE: 'مايوت',
  MEXICO: 'المكسيك',
  MICRONESIA: 'ميكرونيزيا',
  MOLDOVA: 'مولدوفا',
  MONACO: 'موناكو',
  MONGOLIA: 'منغوليا',
  MONTENEGRO: 'الجبل الأسود',
  MONTSERRAT: 'مونتسيرات',
  MOROCCO: 'المغرب',
  MOZAMBIQUE: 'موزمبيق',
  MYANMAR: 'ميانمار',
  NAMIBIA: 'ناميبيا',
  NAURU: 'ناورو',
  NEPAL: 'نيبال',
  NETHERLANDS_ANTILLES: 'جزر الأنتيل الهولندية',
  NETHERLANDS: 'هولندا',
  NEW_CALEDONIA: 'كاليدونيا الجديدة',
  NEW_ZEALAND: 'نيوزيلندا',
  NICARAGUA: 'نيكاراغوا',
  NIGER: 'النيجر',
  NIGERIA: 'نيجيريا',
  NIUE: 'نيوي',
  NORTH_KOREA: 'كوريا الشمالية',
  NORTHERN_MARIANA_ISLANDS: 'جزر مريانا الشمالية',
  NORWAY: 'النرويج',
  OMAN: 'سلطنة عمان',
  PAKISTAN: 'باكستان',
  PALAU: 'بالاو',
  PALESTINE: 'فلسطين',
  PANAMA: 'بنما',
  PAPUA_NEW_GUINEA: 'بابوا غينيا الجديدة',
  PARAGUAY: 'باراجواي',
  PERU: 'بيرو',
  PHILIPPINES: 'فيلبيني',
  PITCAIRN: 'بيتكيرن',
  POLAND: 'بولندا',
  PORTUGAL: 'البرتغال',
  PUERTO_RICO: 'بورتوريكو',
  QATAR: 'دولة قطر',
  REPUBLIC_OF_THE_CONGO: 'جمهورية الكونغو',
  REUNION: 'جمع شمل',
  ROMANIA: 'رومانيا',
  RUSSIA: 'روسيا',
  RWANDA: 'رواندا',
  SAINT_BARTHELEMY: 'سانت بارتيليمي',
  SAINT_HELENA: 'سانت هيلانة',
  SAINT_KITTS_AND_NEVIS: 'سانت كيتس ونيفيس',
  SAINT_LUCIA: 'القديسة لوسيا',
  SAINT_MARTIN: 'القديس مارتن',
  SAINT_PIERRE_AND_MIQUELON: 'سانت بيير وميكلون',
  SAINT_VINCENT_AND_THE_GRENADINES: 'سانت فنسنت وجزر غرينادين',
  SAMOA: 'ساموا',
  SAN_MARINO: 'سان مارينو',
  SAO_TOME_AND_PRINCIPE: 'سان تومي وبرينسيبي',
  SAUDI_ARABIA: 'المملكة العربية السعودية',
  SENEGAL: 'السنغال',
  SERBIA: 'صربيا',
  SEYCHELLES: 'سيشيل',
  SIERRA_LEONE: 'سيرا ليون',
  SINGAPORE: 'سنغافورة',
  SINT_MAARTEN: 'سينت مارتن',
  SLOVAKIA: 'سلوفاكيا',
  SLOVENIA: 'سلوفينيا',
  SOLOMON_ISLANDS: 'جزر سليمان',
  SOMALIA: 'الصومال',
  SOUTH_AFRICA: 'جنوب أفريقيا',
  SOUTH_KOREA: 'كوريا الجنوبية',
  SOUTH_SUDAN: 'جنوب السودان',
  SPAIN: 'إسبانيا',
  SRI_LANKA: 'سيريلانكا',
  SUDAN: 'السودان',
  SURINAME: 'سورينام',
  SVALBARD_AND_JAN_MAYEN: 'سفالبارد وجان ماين',
  SWAZILAND: 'سوازيلاند',
  SWEDEN: 'السويد',
  SWITZERLAND: 'سويسرا',
  SYRIA: 'سوريا',
  TAIWAN: 'تايوان',
  TAJIKISTAN: 'طاجيكستان',
  TANZANIA: 'تنزانيا',
  THAILAND: 'تايلاند',
  TOGO: 'توجو',
  TOKELAU: 'توكيلاو',
  TONGA: 'تونغا',
  TRINIDAD_AND_TOBAGO: 'ترينداد وتوباغو',
  TUNISIA: 'تونس',
  TURKEY: 'تركيا',
  TURKMENISTAN: 'تركمانستان',
  TURKS_AND_CAICOS_ISLANDS: 'جزر تركس وكايكوس',
  TUVALU: 'توفالو',
  US_VIRGIN_ISLANDS: 'جزر فيرجن الأمريكية',
  UGANDA: 'أوغندا',
  UKRAINE: 'أوكرانيا',
  UNITED_ARAB_EMIRATES: 'الإمارات العربية المتحدة',
  UNITED_KINGDOM: 'المملكة المتحدة',
  UNITED_STATES: 'الولايات المتحدة',
  URUGUAY: 'أوروغواي',
  UZBEKISTAN: 'أوزبكستان',
  VANUATU: 'فانواتو',
  VATICAN: 'الفاتيكان',
  VENEZUELA: 'فنزويلا',
  VIETNAM: 'فيتنام',
  WALLIS_AND_FUTUNA: 'واليس وفوتونا',
  WESTERN_SAHARA: 'الصحراء الغربية',
  YEMEN: 'اليمن',
  ZAMBIA: 'زامبيا',
  ZIMBABWE: 'زيمبابوي',
};

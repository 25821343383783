import { ar } from 'vuetify/lib/locale/index.mjs';
import countriesAr from './countries/ar';
import dashboardAr from './dashboard/ar';

export default {
  $vuetify: { ...ar },
  ...countriesAr,
  ...dashboardAr,

  ABOUT_ORGANIZATION: 'عن المؤسسة',
  ABOUT_US: 'من نحن',
  ADDRESS: 'العنوان',
  ALBUMS_LIST: 'قائمة الألبومات',
  ALBUMS: 'الألبومات',
  ALL: 'الكل',
  ARTICALS_LIST: 'قائمة المقالات',
  COMING_SOON: 'سوف يتم توفير المحتوى الخاص بهذا القسم قريباً ...',
  COMMUNICATING_WITH_CEO: 'التواصل مع المدير التنفيذي: الأستاذ/شعلان الشمري',
  CONTACT_FORM_TYPE: 'النوع',
  CONTACT_US: 'تواصل معنا',
  COPYRIGHT: 'جميع الحقوق محفوظة لـ',
  EVENTS_LIST: 'الأحداث والفعاليات',
  EVENTS: 'الأحداث والفعاليات',
  FAILED_SINDING_MESSAGE:
    'عذرًا، يبدو أن هناك خلل في إرسال الرسالة. يُرجى المحاولة مرة أخرى.',
  FAILED_TO_LOAD_DATA: 'فشل في تحميل البيانات!',
  GET_TO_KNOW_US: 'تعرف علينا',
  GOVERNANCES: 'ملفات الحوكمة',
  GOVERNANCES_LIST: 'ملفات الحوكمة',
  HAPPY_FOR_YOUR_CONTACT: 'يسعدنا تواصلكم',
  HOME_PAGE: 'الرئيسية',
  IDENTITIES: 'الهوية البصرية',
  INTERESTING_LINKS: 'روابط تهمك',
  ISSUE: 'مشكلة',
  MEDIA_WINDOW: 'النافذة الإعلامية',
  NEWS_LIST: 'قائمة الأخبار',
  NEWS: 'الأخبار',
  OUR_GOALS: 'أهدافنا',
  OUR_MISSION: 'رسالتنا',
  OUR_PARTNERS: 'شركاء النجاح',
  OUR_PRODUCTS: 'أبرز المنتجات',
  OUR_PROJECTS: 'أبرز المشاريع',
  OUR_STRATEGIC: 'التوجه الاستراتيجي',
  OUR_VALUES: 'قيمنا',
  OUR_VISION: 'رؤيتنا',
  PARTNERS_LIST: 'قائمة شركاء النجاح',
  PARTNERS: 'شركائنا',
  PRODUCT_AND_PROJECT: 'المنتجات والمشاريع',
  PRODUCTS_LIST: 'قائمة المنتجات',
  PROJECTS_LIST: 'أبرز المشاريع',
  QUESTION: 'سؤال',
  REPORTS_LIST: 'قائمة التقارير',
  REPORTS: 'التقارير',
  SEND: 'إرسال',
  SENDER_EMAIL: 'بريد المرسل',
  SENDER_NAME: 'اسم المرسل',
  SENDER_PHONE: 'هاتف المرسل',
  SENT_SUCCESSFULLY: 'تم الإرسال بنجاح وسوف يتم التواصل معك',
  SUGGESTION: 'اقتراح',
  TEAM_RESPONSIBILITIES: 'المسئوليات',
  TEAMS_LIST: 'فرق ولجان العمل',
  TEL_EXT: 'تحويلة',
  THE_MESSAGE: 'محتوى الرسالة',
  THE_TITLE: 'عنوان الرسالة',
  WE_APPRECIATE_YOUR_VISIT: 'نشكر لكم زيارتكم لموقعنا الإلكتروني',
  WEBSITE_ROAD_MAP: 'خريطة الموقع',

  // 404
  PAGE_NOT_FOUND: '404 الصفحة غير موجودة',
  PAGE_NOT_EXIST: 'الصفحة التي تبحث عنها غير موجودة.',
  GO_BACK: 'العودة',
  GO_TO_HOME: 'الرئيسية',
};

import axios, { AxiosError } from 'axios';

const mode: 'local' | 'production' = 'production';
const localBaseURL = 'http://127.0.0.1:8000';
const productionBaseURL = 'https://api.makhzaan.com/alotaishan/public';
// const productionBaseURL = 'http://api.otaishan.org.sa/public';

// const baseUrl = localBaseURL
const baseUrl = productionBaseURL;

const csrfApi = axios.create({
  baseURL: baseUrl + '/sanctum/csrf-cookie',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
});

const backendApi = axios.create({
  baseURL: baseUrl + '/api/v1',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});

export {
  backendApi,
  csrfApi,
  axios,
  AxiosError,
  mode,
  localBaseURL,
  productionBaseURL,
  baseUrl,
};

export default {
  ACCEPTED: 'مقبول',
  ACCOUNTS: 'الحسابات',
  ACTIVE: 'نشط',
  ADD_IMAGES: 'إضافة صور',
  ADD_MEMBER: 'إضافة عضو',
  ADD_RESPONSIBILITY: 'إضافة مسؤولية',
  ADD_VIDEO: 'إضافة فيديو',
  ADDING_IMAGES: 'إضافة صور',
  ADDING_VIDEO: 'إضافة فيديو',
  ADDRESS_AR: 'العنوان (عربي)',
  ADDRESS_EN: 'العنوان (إنجليزي)',
  AL_OTAISHAN_CHARITY_VERSION: 'جمعية العطيشان الخيرية الإصدار 1.0',
  ALBUM_IMAGES: 'صور الألبوم',
  ALBUM_IMAGES_AND_VIDEOS: 'صور وفيديو الألبوم',
  ALOTAISHAN_CHARITY: 'مؤسسة عبدالرحمن بن صالح العطيشان وعائلته الخيرية',
  AN_ERROR_OCCURED_PLEASE_TRY_AGAIN_LATER: 'حدث خطأ، يرجى المحاولة مرة أخرى!',
  AR: 'عربي',
  ARABIC: 'عربي',
  ARABIC_TEXT: 'النص العربي',
  ARTICAL: 'مقالة',
  ARTICALS: 'المقالات',
  BLOCKED: 'محظور',
  CANCEL: 'تراجع',
  CANCELED: 'ملغي',
  CAPTION_AR: 'تسمية توضيحية (عربي)',
  CAPTION_EN: 'تسمية توضيحية (إنجليزي)',
  CLEAR: 'تفريغ الخانات',
  CLEAR_SEARCH: 'تفريغ الخانات',
  CLOSE: 'إغلاق',
  CONTACT_CHANNELS: 'قنوات التواصل',
  CONTACT_CHANNELS_LIST: 'قائمة قنوات الاتصال',
  CONTENT: 'المحتوى',
  COVER_IMAGE: 'صورة الغلاف',
  DASHBOARD: 'لوحة البيانات',
  DELETE: 'حذف',
  DELETE_ALBUM: 'حذف الألبوم',
  DELETE_CONTACT_CHANNEL: 'حذف قناة الاتصال',
  DELETE_GOAL: 'حذف الهدف',
  DELETE_GOVERNANCE: 'حذف ملف الحوكمة',
  DELETE_IDENTITY: 'حذف ملف الهوية',
  DELETE_MEMBER: 'حذف العضو',
  DELETE_MESSAGE: 'حذف رسالة',
  DELETE_ORGANIZATION: 'حذف المنظمة',
  DELETE_PARTNER: 'حذف الشريك',
  DELETE_POST: 'حذف المنشور',
  DELETE_PROJECT: 'حذف المشروع',
  DELETE_REPORT: 'حذف التقرير',
  DELETE_SLIDER: 'حذف البنر',
  DELETE_TEAM: 'حذف الفريق',
  DELETE_USER: 'حذف المستخدم',
  DELETE_VALUE: 'حذف القيمة',
  DELETED: 'محذوف',
  DESCRIPTION: 'وصف',
  DESCRIPTION_AR: 'الوصف (عربي)',
  DESCRIPTION_EN: 'الوصف (إنجليزي)',
  EDIT: 'تعديل',
  EMAIL: 'بريد إلكتروني',
  EN: 'English',
  ENGLISH: 'إنجليزي',
  ENGLISH_TEXT: 'النص الانجليزية',
  EVENT: 'حدث',
  FACEBOOK: 'فيسبوك',
  FAVORITE: 'مفضل',
  FAX: 'فاكس',
  FILE: 'ملف',
  FILE_LINK: 'رابط الملف',
  FILE_WAS_REMOVED_SUCCESSFULLY: 'تمت إزالة الملف بنجاح',
  FILE_WAS_UPLOADED_SUCCESSFULLY: 'تم تحميل الملف بنجاح',
  FILES: 'الملفات',
  FILTER: 'تصفية النتائج',
  GOALS_LIST: 'قائمة الأهداف',
  GOOGLE_MAP: 'خرائط جوجل',
  GOOGLE_MAP_SOURCE: 'مصدر خريطة جوجل',
  GOVERNANCES_LIST: 'ملفات الحوكمة',
  HIDDEN: 'مخفي',
  ICON: 'الأيقونة',
  IDENTITY_LIST: 'الهوية البصرية',
  INACTIVE: 'غير نشط',
  INSTAGRAM: 'انستجرام',
  KEYWORDS: 'الكلمات المفتاحية',
  LANGUAGE: 'اللغة',
  LINK: 'الرابط',
  LINKEDIN: 'لينكد إن',
  LOGIN: 'تسجيل الدخول',
  LOGO: 'الشعار',
  LOGO_WAS_REMOVED_SUCCESSFULLY: 'تمت إزالة الشعار بنجاح',
  LOGO_WAS_UPLOADED_SUCCESSFULLY: 'تم تحميل الشعار بنجاح',
  MEMBER: 'عضو',
  MEMBERS: 'الأعضاء',
  MEMBERS_LIST: 'قائمة الأعضاء',
  MEMBERS_TITLE_AR: 'عنوان الأعضاء (عربي)',
  MEMBERS_TITLE_EN: 'عنوان الأعضاء (English)',
  MENU: 'القائمة',
  MESSAGE: 'رسالة',
  MISSION_AR: 'المهمة (عربي)',
  MISSION_EN: 'المهمة (إنجليزي)',
  NAME: 'الاسم',
  NAME_AR: 'الاسم (عربي)',
  NAME_EN: 'الاسم (إنجليزي)',
  NEW_RECORD: 'سجل جديد',
  OPEN_THE_VIDEO: 'عرض الفيديو',
  ORGANIZATIONS_LIST: 'قائمة المؤسسات',
  OTHER: 'أخرى',
  OUR_ORGANIZATION: 'مؤسستنا',
  PAGE_TITLE: 'عنوان الصفحة',
  PAID: 'مدفوع',
  PARTNER: 'شريك',
  PARTNER_LOGO: 'شعار الشريك',
  PASSWORD: 'كلمة المرور',
  PASSWORD_CONFIRMATION: 'تأكيد كلمة المرور',
  PENDING: 'قيد الانتظار',
  PERSONAL_IMAGE: 'الصورة الشخصية',
  PHONE: 'الهاتف',
  PORJECT_IMAGES: 'صور المشروع',
  POST_IMAGES: 'صور المنشور',
  POSTS: 'المنشورات',
  POSTS_AND_PROJECT: 'المنشورات والمشاريع',
  POSTS_LIST: 'قائمة المنشورات',
  PRINT: 'طباعة',
  PRODUCT: 'منتج',
  PRODUCTS: 'المنتجات',
  PROJECTS: 'المشاريع',
  REFRESH: 'تحديث',
  REJECTED: 'مرفوض',
  REMOVE: 'حذف',
  REPORT_FILE: 'ملف التقرير',
  REQUESTED: 'تم الطلب',
  RESPONSE: 'رد',
  RESPONSED: 'تم الرد',
  RESPONSIBILITIES: 'المسؤوليات',
  ROLE_AR: 'الدور (عربي)',
  ROLE_EN: 'الدور (إنجليزي)',
  SEARCH: 'بحث',
  SELECTED: 'محدد',
  SHOW: 'عرض',
  SHOW_REPORT_FILE: 'عرض التقرير',
  SHOWN: 'معروض',
  SIGN_OUT: 'تسجيل الخروج',
  SLIDER_LIST: 'قائمة البنرات',
  SLIDERS: 'البنرات',
  SNAPCHAT: 'سناب شات',
  STATUS: 'الحالة',
  SUBMIT: 'إرسال',
  SUBTITLE_AR: 'العنوان الفرعي (عربي)',
  SUBTITLE_EN: 'العنوان الفرعي (English)',
  SUPPORT_MESSAGES: 'الدعم الفني',
  SUPPORT_MESSAGES_LIST: 'قائمة الرسائل',
  SYSTEM_USERS: 'مستخدمو النظام',
  TEAMS: 'فرق العمل واللجان',
  TELEGRAM: 'تليجرام',
  TELEPHONE: 'الهاتف',
  TIKTOK: 'تيك توك',
  TITLE: 'العنوان',
  TITLE_AR: 'العنوان (عربي)',
  TITLE_EN: 'العنوان (إنجليزي)',
  TWITTER: 'تويتر',
  TYPE: 'النوع',
  UNKNOWN: 'غير معروف',
  UNPAID: 'غير مدفوع',
  UPLOAD: 'رفع',
  USERS: 'المستخدمين',
  USERS_LIST: 'قائمة المستخدمين',
  VALUE: 'قيمة',
  VALUE_AR: 'القيمة (عربي)',
  VALUE_EN: 'القيمة (إنجليزي)',
  VALUES_LIST: 'قائمة القيم',
  VIDEO_LINK: 'رابط الفيديو',
  VISION_AR: 'الرؤية (عربي)',
  VISION_EN: 'الرؤية (إنجليزي)',
  VISITOR_LIST: 'سجل الزيارات',
  VISITORS: 'الزيارات',
  WHATSAPP: 'واتساب',
  X: 'X',
  YOUTUBE: 'يوتيوب',
};

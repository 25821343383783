import { type RouteRecordRaw } from 'vue-router';

import AboutPage from '@/views/AboutPage.vue';
import AlbumPage from '@/views/AlbumPage.vue';
import AlbumsPage from '@/views/AlbumsPage.vue';
import ContactUsPage from '@/views/ContactUsPage.vue';
import HomePage from '@/views/HomePage.vue';
import IdentitiesPage from '@/views/IdentitiesPage.vue';
import PartnersPage from '@/views/PartnersPage.vue';
import PostPage from '@/views/PostPage.vue';
import PostsList from '@/views/PostsList.vue';
import ProjectPage from '@/views/ProjectPage.vue';
import ProjectsPage from '@/views/ProjectsPage.vue';
import ReportsPage from '@/views/ReportsPage.vue';
import TeamPage from '@/views/TeamPage.vue';
import GovernancesPage from '@/views/GovernancesPage.vue';
import Error404Page from '@/views/Error404Page.vue';

export const routes: RouteRecordRaw[] = [
  { path: '/', name: 'homePage', component: HomePage },
  { path: '/about', name: 'aboutPage', component: AboutPage },

  // governances
  {
    path: '/governances',
    name: 'governancesPage',
    component: GovernancesPage,
  },

  // Albums
  { path: '/albums', name: 'albumsPage', component: AlbumsPage },
  { path: '/albums/:id(\\d+)', name: 'albumPage', component: AlbumPage },

  // contact us
  { path: '/contact-us', name: 'contactUsPage', component: ContactUsPage },

  // identities
  { path: '/identities', name: 'identitiesPage', component: IdentitiesPage },

  // partners
  { path: '/partners', name: 'partnersPage', component: PartnersPage },

  // posts
  {
    path: '/posts',
    name: 'allPosts',
    redirect: () => {
      return { name: 'postsList', params: { type: 'news' } };
    },
  },
  {
    path: '/posts/:type(news|events|articals|products)',
    name: 'postsList',
    component: PostsList,
  },
  { path: '/posts/:id(\\d+)', name: 'postPage', component: PostPage },

  // projects
  { path: '/projects', name: 'projectsPage', component: ProjectsPage },
  { path: '/projects/:id(\\d+)', name: 'projectPage', component: ProjectPage },

  // resports
  { path: '/reports', name: 'reportsPage', component: ReportsPage },

  // teams
  { path: '/teams/:id(\\d+)', name: 'teamPage', component: TeamPage },

  // 404
  { path: '/404', name: '404', component: Error404Page },
  { path: '/:pathMatch(.*)*', component: Error404Page },
];

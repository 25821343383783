import { createRouter, createWebHistory, type Router } from 'vue-router';
import { routes } from '@/router/routes';

const router: Router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes,
});

export default router;

import { en } from 'vuetify/lib/locale/index.mjs';
import countriesEn from './countries/en';
import dashboardEn from './dashboard/en';

export default {
  $vuetify: { ...en },
  ...countriesEn,
  ...dashboardEn,

  ABOUT_ORGANIZATION: 'Our Company',
  ABOUT_US: 'About us',
  ADDRESS: 'Address',
  ALBUMS_LIST: 'Albums List',
  ALBUMS: 'Albums',
  ALL: 'All',
  ARTICALS_LIST: 'Artical List',
  COMING_SOON: 'The content for this section will be provided soon...',
  COMMUNICATING_WITH_CEO: 'Communicating with the CEO: Mr. Shalan Al Shammari',
  CONTACT_FORM_TYPE: 'Type',
  CONTACT_US: 'Contact us',
  COPYRIGHT: 'All rights reserved, ',
  EVENTS_LIST: 'Event List',
  EVENTS: 'Events',
  FAILED_SINDING_MESSAGE: 'Failed to send your message!',
  FAILED_TO_LOAD_DATA: 'Failed to load data!',
  GET_TO_KNOW_US: 'Get to know us',
  GOVERNANCES: 'Governances',
  GOVERNANCES_LIST: 'Governances List',
  HAPPY_FOR_YOUR_CONTACT: 'Contact us',
  HOME_PAGE: 'home',
  IDENTITIES: 'Identities',
  INTERESTING_LINKS: 'Important links',
  ISSUE: 'Issue',
  MEDIA_WINDOW: 'Media Window',
  NEWS_LIST: 'News List',
  NEWS: 'News',
  OUR_GOALS: 'Our Goals',
  OUR_MISSION: 'Our Mission',
  OUR_PARTNERS: 'Our Partners',
  OUR_PRODUCTS: 'Our Products',
  OUR_PROJECTS: 'Our Projects',
  OUR_STRATEGIC: 'Our Strategic',
  OUR_VALUES: 'Our Values',
  OUR_VISION: 'Our Vision',
  PARTNERS_LIST: 'Partners List',
  PARTNERS: 'Partners',
  PRODUCT_AND_PROJECT: 'Product & Project',
  PRODUCTS_LIST: 'Products List',
  PROJECTS_LIST: 'Projects List',
  QUESTION: 'Question',
  REPORTS_LIST: 'Reports List',
  REPORTS: 'Reports',
  SEND: 'Send',
  SENDER_EMAIL: 'Sender Email',
  SENDER_NAME: 'Sender Name',
  SENDER_PHONE: 'Sender Phone',
  SENT_SUCCESSFULLY: 'Sent successfully, and we will be in touch with you.',
  SUGGESTION: 'Suggestion',
  TEAM_RESPONSIBILITIES: 'Responsibilities',
  TEAMS_LIST: 'Work Teams',
  TEL_EXT: 'Ext.',
  THE_MESSAGE: 'Message content',
  THE_TITLE: 'Message title',
  WE_APPRECIATE_YOUR_VISIT: 'We appreciate your visit to our website',
  WEBSITE_ROAD_MAP: 'Road Map',

  // 404
  PAGE_NOT_FOUND: '404 Page Not Found',
  PAGE_NOT_EXIST: 'The page you are looking for does not exist.',
  GO_BACK: 'Go Back',
  GO_TO_HOME: 'Go to Home',
};
